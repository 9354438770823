import React, { useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { DateTimePicker, TimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import ListSubheader from "@material-ui/core/ListSubheader";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import { blue } from "@material-ui/core/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ChipInput from "components/ChipInput";

import ISXUtils, { ISXContext } from "services/Utils";

// maxinum number of characters allowed in description field for each alarm
const MAX_DESCRIPTION_LENGTH = 60;

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  innerContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
  },
  email: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  emailChipInput: {
    width: "100%",
  },
  timePicker: {
    width: "100%",
    maxWidth: "100%",
    margin: 6,
  },
  inputs: {
    fontSize: 12,
  },
  inputsContainer: {
    height: "100%",
  },
  inputLabels: {
    fontSize: 12,
  },
  smsConsent: {
    zIndex: theme.zIndex.modal,
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

const conditions = [
  "Equals",
  "Greater than",
  "Greater than equals",
  "Less than",
  "Less than equals",
  "Between",
  "Outside",
  "Not Equals",
  "Any",
];

const stateChangeConditions = [
  "Becomes Equal To",
  "Becomes Greater than",
  "Becomes Greater than Equals",
  "Becomes Less than",
  "Becomes Less than Equals",
  "Becomes Between",
  "Becomes Outside",
  "Becomes Not Equals",
];

const messageLimits = [
  ["Once per 1 minute", 60],
  ["Once per 3 minutes", 180],
  ["Once per 5 minutes", 300],
  ["Once per 15 minutes", 900],
  ["Once per 1 hour", 3600],
  ["Once per 2 hours", 7200],
  ["Once per 6 hours", 21600],
  ["Once per 12 hours", 43200],
  ["Once per 1 day", 86400],
];

const schPeriod = [
  ["Always", "none"],
  ["Daily", "daily"],
  ["Fixed", "absolute"],
];

const durationOptions = {
  0: "Instantaneous",
  10: "10 seconds",
  30: "30 seconds",
  60: "1 Minute",
  120: "2 Minutes",
  300: "5 Minutes",
  600: "10 Minutes",
  900: "15 Minutes",
  1800: "30 Minutes",
  3600: "1 hour",
  10800: "3 hours",
  21600: "6 hours",
  43200: "12 hours",
};

const AlarmItem = (props) => {
  const { thisStack, saveThisAlarm, alarm, readOnly } = props;

  const isxContext = useContext(ISXContext);

  const classes = useStyles();

  const parseSendTos = (sendTos) => {
    console.log("SENDTOS", sendTos);
    return sendTos.map((s) => {
      if (typeof s === "string") {
        return s;
      } else if (typeof s === "object" && "label" in s) {
        return s.label;
      } else {
        return s.toString();
      }
    });
  };

  const parseConsentRequired = (alarm) => {
    //theoretically this is a detect phone number with country code regex
    const phoneRegexp = new RegExp(
      "^(\\+\\d{1,2}(\\s)?)(\\(?\\d+\\)?[\\s.-]?\\d+)+$"
    );
    return parseSendTos(alarm.sendto || []).some((a) => phoneRegexp.exec(a));
  };

  const consentRequired = parseConsentRequired(alarm);

  useEffect(() => {
    if (alarm.schedule?.start === 0 || alarm.schedule?.end === 0) {
      const schedule = { ...alarm.schedule };
      if (alarm.schedule?.start === 0) {
        schedule.start = parseInt(moment().valueOf() / 1000);
      }
      if (alarm.schedule?.end === 0) {
        schedule.end = parseInt(moment().add(1, "hour").valueOf() / 1000);
      }
      saveThisAlarm(thisStack.guuid, { ...alarm, schedule });
    }
  }, [alarm, thisStack.guuid, saveThisAlarm]);

  const handleChange = (event) => {
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      [event.target.name]: event.target.value,
    });
  };

  const handleScheduleStartChange = useCallback(
    (dt) => {
      const schedule = { ...alarm.schedule };
      schedule.start = parseInt(dt.valueOf() / 1000);
      // console.log("schedule start is", dt, schedule.start);
      saveThisAlarm(thisStack.guuid, { ...alarm, schedule });
    },
    [alarm, thisStack.guuid, saveThisAlarm]
  );

  const handleScheduleEndChange = useCallback(
    (dt) => {
      const schedule = { ...alarm.schedule };
      schedule.end = parseInt(dt.valueOf() / 1000);
      // console.log("schedule end is", dt, schedule.end);
      saveThisAlarm(thisStack.guuid, { ...alarm, schedule });
    },
    [alarm, thisStack.guuid, saveThisAlarm]
  );

  const handleTag = (event) => {
    if (event.target.value) {
      let tc = "";
      let derivedtag = "sample";
      if (event.target.value.startsWith("isxreserved|")) {
        tc = [event.target.value];
      } else {
        tc = event.target.value.split("|");

        if (tc.length > 1) {
          derivedtag = tc[1];
        }
      }
      saveThisAlarm(thisStack.guuid, {
        ...alarm,
        tagName: tc[0],
        derivedtag: derivedtag,
      });
    }
  };

  const handleConditionChange = (event, conditionIndex) => {
    const newCdnVal = [...alarm.cdn];
    const t =
      conditionIndex === 1 && event.target.value.indexOf(".") >= 0
        ? parseFloat(event.target.value).toFixed(newCdnVal[0] === 1 ? 0 : 2) //force to be an int if EQUALS condition
        : event.target.value;
    newCdnVal[conditionIndex] = parseFloat(t);
    saveThisAlarm(thisStack.guuid, { ...alarm, cdn: newCdnVal });
  };

  const handlePeriodChange = (event) => {
    const schedule = { ...alarm.schedule, period: event.target.value };
    saveThisAlarm(thisStack.guuid, { ...alarm, schedule });
  };

  const handleDurationChange = (newValue) => {
    if (newValue === null) {
      newValue = "0";
    }
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      duration: newValue,
      drlabel: durationOptions[newValue],
    });
  };

  const handleCheckboxChange = (event) => {
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      [event.target.name]: !alarm[event.target.name],
    });
  };

  const handleDescriptionChange = (event) => {
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      description: event.target.value.slice(0, MAX_DESCRIPTION_LENGTH),
    });
  };

  const handleDeleteThisAlarm = () => {
    props.deleteThisAlarm(props.thisStack.guuid, alarm.guuid);
  };

  const handleAddEmailChip = (chip) => {
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      sendto: [...alarm.sendto, chip],
    });
  };

  const handleEmailChange = (emails) => {
    saveThisAlarm(thisStack.guuid, {
      ...alarm,
      sendto: emails,
    });
  };

  const handleDeleteEmailChip = (chip, index) => {
    const newArray = alarm.sendto.filter((contact, i) => {
      return index !== i;
    });
    saveThisAlarm(thisStack.guuid, { ...alarm, sendto: newArray });
  };

  const derivedTagSeparator = "|";
  const localComputeIndicator = "lc";
  const movingAverage = "ma";
  const windowSpec = "10sm";
  let derivedTag =
    derivedTagSeparator +
    movingAverage +
    "-" +
    windowSpec +
    "-" +
    localComputeIndicator;
  const stackMatches = props.stacks?.filter(
    (stack) => stack.guuid === props.thisStack.guuid
  );
  const stack = stackMatches?.length === 1 && stackMatches[0];
  const attributes = (stack?.data_config?.dsitems ?? [])
    .filter((ds) => !ds.endsWith("_aggcount"))
    .map((tid) => {
      return { label: tid, name: ISXUtils.getTagName(tid, stack?.data_config) };
    })
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  return (
    <Grid container className={classes.outerContainer}>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        wrap="wrap"
        justifyContent="space-around"
        className={classes.innerContainer}
      >
        <Grid item xs={6} md={3}>
          <FormControl fullWidth={true}>
            <InputLabel className={classes.inputLabels}>Tag</InputLabel>
            <Select
              value={
                alarm.tagName +
                (alarm.derivedtag && alarm.derivedtag !== "sample"
                  ? "|" + alarm.derivedtag
                  : "")
              }
              onChange={handleTag}
              type="number"
              name="tagName"
              className={classes.inputs}
              autoFocus={!alarm.tagName}
              disabled={readOnly}
            >
              {attributes.map((attr) => {
                return (
                  <MenuItem value={attr.label} key={attr.label}>
                    {attr.name}
                  </MenuItem>
                );
              })}
              <ListSubheader>Moving Average (10 Samples)</ListSubheader>
              {attributes.map((attr) => {
                return (
                  <MenuItem
                    value={attr.label + derivedTag}
                    key={attr.label + derivedTag}
                  >
                    {attr.name + " (Avg)"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={2}>
          <FormControl fullWidth={true}>
            <InputLabel className={classes.inputLabels}>Condition</InputLabel>
            <Select
              name="condition"
              // required
              value={alarm.cdn[0]}
              onChange={(event) => handleConditionChange(event, 0)}
              disabled={readOnly || alarm.tag === ""}
              className={classes.inputs}
            >
              {conditions.map((cdn) => {
                return (
                  // +1 is needed because the conditions are 1-indexed on the backend
                  <MenuItem
                    value={conditions.findIndex((x) => x === cdn) + 1}
                    key={cdn}
                  >
                    {cdn}
                  </MenuItem>
                );
              })}
              {stateChangeConditions.map((cdn) => {
                return (
                  // +1 is needed because the conditions are 1-indexed on the backend
                  <MenuItem
                    value={
                      stateChangeConditions.findIndex((x) => x === cdn) + 101
                    }
                    key={cdn}
                  >
                    {cdn}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth={true}>
            <InputLabel className={classes.inputLabels}>
              Trigger Value
              {props.thisStack != null &&
              props.thisStack.st != null &&
              props.thisStack.st[alarm.tagName] != null
                ? " (Recent value:  " +
                  props.thisStack.st[alarm.tagName][0][1] +
                  " @ " +
                  new Date(
                    props.thisStack.st[alarm.tagName][0][0]
                  ).toLocaleTimeString() +
                  ")"
                : " (No recent value found)"}
            </InputLabel>
            <Input
              name="value"
              value={alarm.cdn[1]}
              placeholder="00.00"
              type="number"
              onChange={(event) => handleConditionChange(event, 1)}
              disabled={readOnly || alarm.tag === "" || alarm.cdn === 9}
              className={classes.inputs}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth={true}>
            <Autocomplete
              value={alarm.duration || "0"}
              onChange={(event, newValue) => {
                handleDurationChange(newValue);
              }}
              autoComplete
              id="controllable-states-demo"
              options={Object.keys(durationOptions)}
              getOptionLabel={(option) =>
                durationOptions[option] || option.toString()
              }
              disabled={readOnly || alarm.tag === ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="For Duration"
                  InputLabelProps={{ className: classes.inputLabels }}
                />
              )}
              classes={{ input: classes.inputs }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={
            alarm.schedule?.period === "daily" ||
            alarm.schedule?.period === "absolute"
              ? 2
              : 2
          }
          md={
            alarm.schedule?.period === "daily" ||
            alarm.schedule?.period === "absolute"
              ? 1
              : 1
          }
        >
          <FormControl fullWidth={true}>
            <InputLabel className={classes.inputLabels}>Schedule</InputLabel>
            <Select
              name="scperiod"
              // required
              value={alarm.schedule?.period}
              onChange={(event) => handlePeriodChange(event)}
              disabled={readOnly || alarm.tag === ""}
              className={classes.inputs}
            >
              {schPeriod.map((pr) => {
                return (
                  <MenuItem value={pr[1]} key={pr}>
                    {pr[0]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {alarm.schedule?.period === "daily" && (
          <React.Fragment>
            <Grid item xs={2} md={1}>
              <TimePicker
                error={false}
                id="starttime"
                label="Start"
                value={
                  alarm.schedule?.start === 0
                    ? moment()
                    : moment.unix(alarm.schedule?.start)
                }
                onChange={(dt) => handleScheduleStartChange(dt)}
                InputLabelProps={{ className: classes.inputLabels }}
                InputProps={{ className: classes.inputs }}
                className={classes.timePicker}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={2} md={1}>
              <TimePicker
                error={false}
                id="endtime"
                label="End"
                value={
                  alarm.schedule?.end === 0
                    ? moment()
                    : moment.unix(alarm.schedule?.end)
                }
                onChange={(dt) => handleScheduleEndChange(dt)}
                InputLabelProps={{ className: classes.inputLabels }}
                InputProps={{ className: classes.inputs }}
                className={classes.timePicker}
                disabled={readOnly}
              />
            </Grid>
          </React.Fragment>
        )}

        {alarm.schedule?.period === "absolute" && (
          <React.Fragment>
            <Grid item xs={2} md={1}>
              <DateTimePicker
                error={false}
                id="starttime"
                label="Start"
                value={
                  alarm.schedule?.start === 0
                    ? moment()
                    : moment.unix(alarm.schedule?.start)
                }
                onChange={(dt) => handleScheduleStartChange(dt)}
                InputLabelProps={{ className: classes.inputLabels }}
                InputProps={{ className: classes.inputs }}
                className={classes.timePicker}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={2} md={1}>
              <DateTimePicker
                error={false}
                id="endtime"
                label="End"
                value={
                  alarm.schedule?.end === 0
                    ? moment()
                    : moment.unix(alarm.schedule?.end)
                }
                onChange={(dt) => handleScheduleEndChange(dt)}
                InputLabelProps={{ className: classes.inputLabels }}
                InputProps={{ className: classes.inputs }}
                className={classes.timePicker}
                disabled={readOnly}
              />
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12} md={6} fullWidth>
          <div className={classes.email}>
            <div style={{ width: "100%" }}>
              <Tooltip
                title={
                  alarm.sendto && alarm.sendto.length > 0
                    ? "Type valid email address or full phone # (including country code) and hit Enter to add more"
                    : "Type valid email address or full phone # (including country code) and hit Enter to add"
                }
                placement="top"
              >
                <div>
                  <ChipInput
                    label="Email/Phone"
                    variant="outlined"
                    value={parseSendTos(alarm.sendto || [])}
                    onAdd={(chip) => handleAddEmailChip(chip)}
                    onDelete={(chip, index) =>
                      handleDeleteEmailChip(chip, index)
                    }
                    allowDuplicates={false}
                    disabled={readOnly}
                  />
                </div>
              </Tooltip>
              {consentRequired && (
                <div>
                  <FormControlLabel
                    classes={{ label: classes.inputLabels }}
                    style={{ marginRight: 6 }}
                    name="consent"
                    checked={alarm.consent}
                    onClick={handleCheckboxChange}
                    control={<Checkbox size="small" />}
                    disabled={readOnly}
                    label="Consent to receive SMS"
                  />
                  <Tooltip
                    interactive
                    title={
                      <p>
                        You agree to receive automated system alert text
                        messages from {isxContext.labels.organization} based on
                        criteria you enter in the {isxContext.labels.platform}{" "}
                        online machine and sensor monitoring platform. Terms and
                        privacy policy can be found at{" "}
                        <a
                          href={isxContext.smsTermsUse}
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://interstacks.com/sms-terms
                        </a>{" "}
                        . Reply STOP to end.
                      </p>
                    }
                  >
                    <InfoIcon fontSize="small" style={{ color: blue[500] }} />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </Grid>

        <Grid item container xs={12} md={6} spacing={1}>
          <Grid item xs={12}>
            <Tooltip
              title="Type optional description for the Alert"
              placement="top"
            >
              <FormControl fullWidth={true}>
                <TextField
                  label="Description"
                  value={alarm.description || ""}
                  onChange={handleDescriptionChange}
                  InputLabelProps={{ className: classes.inputLabels }}
                  InputProps={{ className: classes.inputs }}
                  disabled={readOnly}
                />
              </FormControl>
            </Tooltip>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel className={classes.inputLabels}>
                Alert Limit
              </InputLabel>
              <Select
                name="almszd"
                value={alarm.almszd}
                onChange={handleChange} //ON CHANGE
                className={classes.inputs}
                disabled={readOnly}
              >
                {messageLimits.map((ml) => {
                  return (
                    <MenuItem value={ml[1]} key={ml}>
                      {ml[0]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                classes={{ label: classes.inputLabels }}
                name="enabled"
                checked={alarm.enabled}
                onClick={handleCheckboxChange}
                control={<Checkbox />}
                disabled={readOnly || alarm.tag === ""}
                label="Enable"
              />
            </FormGroup>

            {!readOnly && (
              <Button
                onClick={handleDeleteThisAlarm}
                size="small"
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Remove Alert
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlarmItem;
