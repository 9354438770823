import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import Options from "./widget-options/AllWidgetConfigureOptions";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  exit: {
    textAlign: "right",
    float: "right",
    padding: 0,
  },
  cancel: {
    color: "red",
  },
  option: {
    display: "inline-block",
    width: "100%",
    borderBottom: "1px solid lightgray",
    "&:last-child": {
      borderBottom: 0,
    },
  },
});

class WidgetConfigureDialog extends React.Component {
  constructor(props) {
    super(props);
    const { widget, dataProperty } = props;

    this.state = {
      activeStep: 0,
      title: widget.title,
      nonLinearStep: (widget[dataProperty] || []).length > 0,
      options: {
        ...(widget.options || {}),
      },
      errors: {},
    };

    (props.options || []).forEach((o) => {
      Object.entries(o.defaultMethod || {}).forEach((entry) => {
        const [property, method] = entry;
        if (!this.state.options[property]) {
          this.state.options[property] = method(this.state.options);
        }
      });
      Object.entries(o.default || {}).forEach((entry) => {
        const [property, value] = entry;
        if (!this.state.options[property]) {
          this.state.options[property] = value;
        }
      });
    });
  }

  setOptions = (props) => {
    this.setState({ options: { ...this.state.options, ...props } });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.stacks !== prevProps.stacks) {
      this.setState({ stacks: this.props.stacks });
    }
  };

  nextStep = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  backStep = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  setStep = (step) => () => {
    this.setState({ activeStep: step });
  };

  isNextDisabled = () => {
    return !this.props.isDataValid();
  };

  isFinishDisabled = () => {
    let disabled = false;
    let errorList = this.state.errors;
    for (let error in errorList) {
      if (errorList[error]) {
        disabled = true;
      }
    }
    if (!this.props.isDataValid()) {
      disabled = true;
    }
    return disabled;
  };

  resetState = () => {
    // in order to fix stacks not showing up for table widget I had to remove tags from the reset.
    //If this breaks anything else let's revert back.[AKB]
    this.setState({
      activeStep: 0,
      stacks: this.props.stacks,
    });
  };

  reset = () => {
    this.props.removeDialog();
    this.resetState();
    this.dataDirty = false;
  };

  updateWidget = () => {
    this.props.removeDialog();
    this.setState({ activeStep: 0 });

    const data = this.props.prepareDataForWriteback();
    let update = {
      ...data,
      options: {
        ...(this.props.widget.options || {}),
        ...this.state.options,
      },
    };
    if (this.props.dataDirty) {
      const title = this.props.titleBuilder(data);
      if (
        this.props.widget.title != null &&
        this.props.widget.title !== this.props.widget.default_title //if it has ever been user set, title will NOT match default title..
      ) {
        update.default_title = title; //update only the default title
      } else {
        update.title = update.default_title = title;
      }
    }
    this.props.updateWidget(update);
    this.reset();
  };

  errorDetected = (id) => {
    console.log("error", id);
    let newObj = this.state.errors;
    newObj[id] = true;
    this.setState({ error: newObj });
  };

  noErrorDetected = (id) => {
    // console.log("no error", id);

    let newObj = this.state.errors;
    delete newObj[id];
    this.setState({ error: newObj });
  };

  render = () => {
    if (this.state.nonLinearStep == null) {
      return null;
    }
    const {
      classes,
      open,
      options,
      widget,
      widgetTitle,
      dataLabel,
      removeDialog,
      children,
    } = this.props;
    const { nonLinearStep, activeStep } = this.state;
    return (
      <Dialog fullWidth={true} open={open}>
        <DialogTitle>
          Configure {widgetTitle} Widget
          <IconButton onClick={removeDialog} className={classes.exit}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        {options.length > 0 && (
          <Stepper
            nonLinear={nonLinearStep}
            alternativeLabel
            activeStep={activeStep}
          >
            <Step key={0}>
              {nonLinearStep ? (
                <StepButton onClick={this.setStep(0)}>
                  Select {dataLabel}
                </StepButton>
              ) : (
                <StepLabel>Select {dataLabel}</StepLabel>
              )}
            </Step>
            <Step key={1}>
              {nonLinearStep ? (
                <StepButton
                  disabled={!this.props.isDataValid()}
                  onClick={this.setStep(1)}
                >
                  Options
                </StepButton>
              ) : (
                <StepLabel>Options</StepLabel>
              )}
            </Step>
          </Stepper>
        )}
        <DialogContent>
          {activeStep === 0 && children}
          {activeStep === 1 && (
            <div>
              {options.map((o) => {
                const option =
                  Options[o.type] ?? {
                    component: o.component,
                    includeStacks: o.includeStacks,
                  } ??
                  {};
                return (
                  <div key={o.type} className={classes.option}>
                    <option.component
                      options={this.state.options}
                      setOptions={this.setOptions}
                      errors={this.state.errors}
                      noErrorDetected={this.noErrorDetected}
                      errorDetected={this.errorDetected}
                      setInitialTime={this.setInitialTime}
                      widget={widget}
                      stacksSelected={this.props.stacksSelected}
                      tagsSelected={this.props.tagsSelected}
                      stacks={
                        option.includeStacks ?? false
                          ? this.props.stacks
                          : undefined
                      }
                      {...(o.additionalProperties || {})}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.reset} className={classes.cancel}>
            Cancel
          </Button>
          {this.state.activeStep !== 0 && (
            <Button onClick={this.backStep}>Back</Button>
          )}
          {this.state.activeStep !== 1 && this.props.options.length > 0 && (
            <Button onClick={this.nextStep} disabled={this.isNextDisabled()}>
              Next
            </Button>
          )}
          {(activeStep === 1 || nonLinearStep || options.length === 0) && (
            <Button
              onClick={this.updateWidget}
              disabled={this.isFinishDisabled()}
              variant="contained"
              color="primary"
            >
              Finish
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(styles)(WidgetConfigureDialog);
