import LineChartWidget from "./line-chart-widget/LineChartWidget";
// import BarChartWidget from "./bar-chart-widget/BarChartWidget";
import DatapointWidget from "./datapoint-widget/DatapointWidget";
import TableWidget from "./table-widget/TableWidget";
import StackMessagingWidget from "./stack-messaging-widget/StackMessagingWidget";
import OeeWidget from "./oee-widget/OeeWidget";
import QuickMessagingWidget from "./quick-messaging-widget/QuickMessagingWidget";
import AutolubeWidget from "./autolube-widget/AutolubeWidget";
import LubeHistoryWidget from "./lube-history-widget/LubeHistoryWidget";
import LubeCartridgeWidget from "./lube-cartridge-widget/LubeCartridgeWidget";
import OrganizationManagementWidget from "./organization-management-widget/OrganizationManagementWidget";
import GaugeWidget from "./gauge-widget/GaugeWidget";
import LevelWidget from "./level-widget/LevelWidget";
import StacksManagementWidget from "./stacks-management-widget/StacksManagementWidget";
// import UserManagementWidget from "./user-management-widget/UserManagementWidget";
import AnalyticsWidget from "./analytics-widget/AnalyticsWidget";
import StateTableWidget from "./state-table-widget/StateTableWidget";
import StateHistoryWidget from "./state-history-widget/StateHistoryWidget";
import AlertsWidget from "./alerts-widget/AlertsWidget";
import EventsWidget from "./events-widget/EventsWidget";
import CountsWidget from "./counts-widget/CountsWidget";
import PeriodsWidget from "./PeriodsWidget";
import ShiftWidget from "./ShiftWidget";
import PeriodsTrackingOption from "./PeriodsWidget/PeriodsTrackingOption";
import UnsupportedWidget from "../../components/widgets/unsupported-widget/UnsupportedWidget";
import DashboardConfigurationWidget from "./DashboardConfigurationWidget";
import BLETagsConfigurationWidget from "./BLETagsConfigurationWidget";
import NotesWidget from "./NotesWidget";

const singleValueMetricTypeDefault = (options) => {
  return {
    type: options["interval.policy"] || "snapshot",
    samples: 30,
  };
};

const Widgets = (context) => {
  return {
    DatasetLineChart: {
      component: LineChartWidget,
      group: "Time Series",
      dataType: "tags",
      displayName: "Line Chart",
      properties: {
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "yaxis",
            default: { yaxis: { type: "auto" } },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    // DatasetBarChart: {
    //   component: BarChartWidget,
    //   dataType: "tags",
    //   displayName: "Bar Chart",
    //   properties: {
    //     configurationOptions: [
    //       {
    //         type: "timeframe",
    //         default: {
    //           timeframe: {
    //             type: "moving",
    //             "length.value": 30,
    //             "length.units": "minutes",
    //             "interval.type": "set",
    //             "interval.value": "10",
    //             "interval.units": "seconds"
    //           }
    //         }
    //       },
    //       {
    //         type: "yaxis",
    //         default: { yaxis: { type: "auto" } }
    //       }
    //     ],
    //     minHeight: 8,
    //     minWidth: 4
    //   }
    // },
    Datapoint: {
      component: DatapointWidget,
      group: "Single Value",
      dataType: "tags",
      displayName: "Datapoint",
      properties: {
        configurationOptions: [
          {
            type: "metric",
            defaultMethod: {
              metric: singleValueMetricTypeDefault,
            },
          },
          {
            type: "interval",
            default: {
              "interval.units": "seconds",
              "interval.value": "10",
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 2,
        minWidth: 1,
      },
    },
    Gauge: {
      component: GaugeWidget,
      group: "Single Value",
      dataType: "tags",
      displayName: "Gauge",
      properties: {
        configurationOptions: [
          {
            type: "range",
            default: {
              range: {
                min: "",
                max: "",
              },
            },
          },
          {
            type: "metric",
            defaultMethod: {
              metric: singleValueMetricTypeDefault,
            },
          },
          {
            type: "interval",
            default: {
              "interval.units": "seconds",
              "interval.value": "10",
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 3,
        minWidth: 1,
      },
    },
    Level: {
      component: LevelWidget,
      group: "Single Value",
      dataType: "tags",
      displayName: "Level",
      properties: {
        configurationOptions: [
          {
            type: "range",
            default: {
              range: {
                min: "",
                max: "",
              },
            },
          },
          {
            type: "metric",
            defaultMethod: {
              metric: singleValueMetricTypeDefault,
            },
          },
          {
            type: "interval",
            default: {
              "interval.units": "seconds",
              "interval.value": "10",
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 3,
        minWidth: 1,
      },
    },
    Table: {
      component: TableWidget,
      group: "Time Series",
      dataType: "tags",
      displayName: "Table",
      properties: {
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    StackMessaging: {
      component: StackMessagingWidget,
      group: "Administration",
      dataType: "stacks",
      displayName: `${context.labels.deviceCapitalized} Advanced Messaging`,
      properties: {
        alerts: false,
        configurationOptions: [],
        minHeight: 12,
        minWidth: 4,
      },
    },
    QuickMessaging: {
      component: QuickMessagingWidget,
      group: "Administration",
      dataType: "stacks",
      displayName: `${context.labels.deviceCapitalized} Messaging`,
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "messaging",
            default: {},
          },
        ],
        minHeight: 4,
        minWidth: 2,
      },
    },
    Autolube: {
      component: AutolubeWidget,
      group: "Other",
      dataType: "stacks",
      displayName: "Lubrication Control",
      properties: {
        alerts: false,
        configurationOptions: [],
        minHeight: 5,
        minWidth: 3,
      },
    },
    LubeHistory: {
      component: LubeHistoryWidget,
      group: "Other",
      dataType: "stacks",
      displayName: "Lubrication History",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 180,
                "length.units": "days",
                "interval.type": "set",
                "interval.value": 1,
                "interval.units": "minutes",
              },
            },
          },
        ],
        minHeight: 5,
        minWidth: 2,
      },
    },
    LubeCartridge: {
      component: LubeCartridgeWidget,
      group: "Other",
      dataType: "stacks",
      displayName: "Lubrication Cartridge Status",
      properties: {
        alerts: false,
        configurationOptions: [],
        minHeight: 5,
        minWidth: 2,
      },
    },
    OrganizationManagement: {
      component: OrganizationManagementWidget,
      group: "Administration",
      dataType: "stacks",
      displayName: "Organization Management",
      properties: {
        alerts: false,
        configurable: false,
        configurationOptions: [],
        minHeight: 12,
        minWidth: 4,
      },
    },
    StacksManagement: {
      component: StacksManagementWidget,
      group: "Administration",
      dataType: "stacks",
      displayName: `${context.labels.deviceCapitalized} Management`,
      properties: {
        alerts: false,
        configurable: false,
        configurationOptions: [],
        // configurationOptions: [
        //   {
        //     type: "attributes",
        //     default: { attributes: [] },
        //     additionalProperties: {
        //       allAttributes: [
        //         "label",
        //         "guuid",
        //         "last_updated",
        //         "status",
        //         "location",
        //         "owner"
        //       ]
        //     }
        //   }
        // ],
        minHeight: 8,
        minWidth: 6,
      },
    },
    DatasetAnalytics: {
      component: AnalyticsWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "Signal Statistics",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "yaxis",
            default: { yaxis: { type: "auto" } },
          },
          {
            type: "histogram",
            default: {
              histogram: {
                binCount: 24,
                binStyle: "uniform",
                highPassFilter: false,
              },
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    StateTable: {
      component: StateTableWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "State Table",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "statereporting",
            default: {
              statereporting: {
                outputUnit: "percent",
              },
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    StateHistoryChart: {
      component: StateHistoryWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "State History",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "statereporting",
            default: {
              statereporting: {
                outputUnit: "percent",
              },
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    CountsChart: {
      component: CountsWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "Counts",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
          {
            type: "count",
            default: {
              count: {
                type: "samples",
                condition: "any",
              },
            },
          },
          {
            type: "linearfn",
            default: {
              linearfn: {
                type: "custom",
                tagprop: true,
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 4,
      },
    },
    OEE: {
      component: OeeWidget,
      group: "Analytics",
      dataType: "stacks",
      displayName: "OEE",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "oee",
            default: {
              oee: {
                shortBreakDuration: 15,
                shortBreakNumber: 2,
                mealBreakDuration: 30,
                mealBreakNumber: 1,
                rejectedPieces: 0,
                tolerance: 25,
              },
            },
          },
        ],
        minHeight: 16,
        minWidth: 4,
      },
    },
    Alerts: {
      component: AlertsWidget,
      group: "Analytics",
      dataType: "stacks",
      displayName: "Alerts",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            additionalProperties: {
              showWarning: false,
            },
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 6,
      },
    },
    Events: {
      component: EventsWidget,
      group: "Administration",
      dataType: "stacks",
      displayName: "Event Management",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "timeframe",
            additionalProperties: {
              showWarning: false,
            },
            default: {
              timeframe: {
                type: "moving",
                "length.value": 30,
                "length.units": "minutes",
                "interval.type": "set",
                "interval.value": "10",
                "interval.units": "seconds",
              },
            },
          },
        ],
        minHeight: 8,
        minWidth: 6,
      },
    },
    Periods: {
      component: PeriodsWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "Multi-tag Report",
      properties: {
        alerts: true,
        configurationOptions: [
          {
            type: "periods",
            default: {
              periods: [],
              periods_to_show: 2,
            },
          },
          {
            type: "timezone",
          },
          {
            type: "periodstracking",
            component: PeriodsTrackingOption,
            includeStacks: true,
            default: {
              "periods.tracking": "counts",
              statereporting: {
                states: [
                  {
                    id: 1,
                    label: "Off",
                    lowerBound: "0",
                    upperBound: "15",
                    minDuration: "5",
                    color: "",
                  },
                  {
                    id: 2,
                    label: "On",
                    lowerBound: "10",
                    upperBound: "100",
                    minDuration: "10",
                    color: "",
                  },
                ],
              },
            },
          },
          // {
          //   type: "staterehistoryeporting",
          //   default: {
          //     statereporting: {
          //       states: [
          //         {
          //           id: 1,
          //           label: "Off",
          //           lowerBound: "0",
          //           upperBound: "15",
          //           minDuration: "5",
          //           color: "",
          //         },
          //         {
          //           id: 2,
          //           label: "On",
          //           lowerBound: "10",
          //           upperBound: "100",
          //           minDuration: "10",
          //           color: "",
          //         },
          //       ],
          //     },
          //   },
          // },
        ],
        minHeight: 8,
        minWidth: 6,
      },
    },
    Shift: {
      component: ShiftWidget,
      group: "Analytics",
      dataType: "tags",
      displayName: "OEE Circle",
      properties: {
        alerts: false,
        configurationOptions: [
          {
            type: "periods",
            default: {
              periods: [],
              periods_to_show: 2,
            },
          },
          {
            type: "timezone",
          },
          {
            type: "periodstracking",
            component: PeriodsTrackingOption,
            includeStacks: true,
            default: {
              "periods.tracking": "counts",
              statereporting: {
                states: [
                  {
                    id: 1,
                    label: "Off",
                    lowerBound: "0",
                    upperBound: "15",
                    minDuration: "5",
                    color: "",
                  },
                  {
                    id: 2,
                    label: "On",
                    lowerBound: "10",
                    upperBound: "100",
                    minDuration: "10",
                    color: "",
                  },
                ],
              },
            },
          },
        ],
        minHeight: 7,
        minWidth: 2,
      },
    },
    DashboardConfiguration: {
      component: DashboardConfigurationWidget,
      dataType: "stacks",
      group: "Administration",
      displayName: `${context.labels.deviceCapitalized} Configuration`,
      addable: true,
      properties: {
        alerts: false,
        configurable: false,
        configurationOptions: [],
        minHeight: 14,
        minWidth: 12,
      },
    },
    BLETagsConfiguration: {
      component: BLETagsConfigurationWidget,
      dataType: "stacks",
      group: "Administration",
      displayName: `${context.labels.deviceCapitalized} BLE Configuration`,
      addable: true,
      properties: {
        alerts: false,
        configurable: false,
        configurationOptions: [],
        minHeight: 14,
        minWidth: 12,
      },
    },
    Notes: {
      component: NotesWidget,
      dataType: "tags",
      group: "Other",
      displayName: "Notes",
      addable: true,
      properties: {
        alerts: false,
        configurationOptions: [],
        minHeight: 10,
        minWidth: 4,
      },
    },
    Unsupported: {
      component: UnsupportedWidget,
      addable: false,
      properties: {
        alerts: false,
        configurable: false,
        configurationOptions: [],
      },
    },
  };
};

export default Widgets;
