import TimezoneOption from "./TimezoneOption";
import TimeframeOption from "./timeframe-option/TimeframeOption";
import YAxisOption from "./y-axis-option/YAxisOption";
import IntervalOption from "./interval-option/IntervalOption";
import MetricOption from "./metric-option/MetricOption";
import CountOption from "./count-option/CountOption";
import MessagingOption from "./messaging-option/MessagingOption";
import OeeOption from "./oee-option/OeeOption";
import RangeOption from "./range-option/RangeOption";
import AttributesOptions from "./attributes-option/AttributesOption";
import HistogramOption from "./histogram-option/HistogramOption";
import StateHistoryReportingOptions from "./state-history-reporting-properties/StateHistoryReportingOptions";
import StateReportingOption from "./StateReportingOption";
import LinearFunction from "./linear-function-option/LinearFunction";
import PeriodsOption from "./PeriodsOption";

const Options = {
  timezone: { component: TimezoneOption },
  timeframe: { component: TimeframeOption },
  yaxis: { component: YAxisOption },
  interval: { component: IntervalOption },
  metric: { component: MetricOption },
  count: { component: CountOption },
  messaging: { component: MessagingOption },
  oee: { component: OeeOption },
  range: { component: RangeOption },
  attributes: { component: AttributesOptions },
  histogram: { component: HistogramOption },
  staterehistoryeporting: { component: StateHistoryReportingOptions },
  statereporting: { component: StateReportingOption },
  linearfn: { component: LinearFunction },
  periods: { component: PeriodsOption },
};

export default Options;
