const stateTableProcessingWorker = () => {
  const UNCATEGORIZED_STATE_ID = 99;
  const NO_DATA_STATE_ID = 100;

  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener("message", (e) => {
    if (!e) return;

    const { dataset, startTime, endTime, states, edgesOnly, stateCounts, key } =
      e.data;

    const eventInterval =
      dataset.eventInterval || computeAverageInterval(dataset.rawData);

    // if min duration for missing data provided and is long enough, use it,
    // otherwise use default
    const defaultCutoffTime = 2 * eventInterval;
    const cutoffTime =
      dataset.missingDataCutoff > defaultCutoffTime
        ? dataset.missingDataCutoff
        : defaultCutoffTime;

    const stateLabels = states.map((state) => state.label);

    const result = !edgesOnly
      ? convertTimeSeriesAndStateMapToHistory(
          dataset.rawData,
          states,
          eventInterval,
          cutoffTime
        )
      : convertEdgesOnlyTimeSeriesToHistory(dataset.rawData, stateLabels);
    if (!!result) {
      // If no values, create one for the missing data
      if (!result.history || result.history.length === 0) {
        result.history = [
          {
            id: NO_DATA_STATE_ID,
            label: "No Data",
            start: startTime,
            end: endTime,
            minDuration: 0,
          },
        ];
      } else {
        // If we're missing data at the start of the intended period, fill it in
        const firstState = result.history[result.history.length - 1];
        if (firstState.start > startTime) {
          if (edgesOnly) {
            // assume new first state opposite of prior first state
            result.history.push({
              // ids are offset by 1 .. off is 1 and on is 2 .. go figure!
              id: firstState.id === 1 ? 2 : 1,
              label:
                firstState.id === 1
                  ? stateLabels[1] //"On"
                  : stateLabels[0], //"Off",
              start: startTime,
              end: firstState.start,
              minDuration: 0,
            });
          } else {
            // maintain state if already in no data state, or if not in no data
            // state and haven't reached cutoff time for transition to no data
            // state
            if (
              firstState.id === NO_DATA_STATE_ID ||
              firstState.start - startTime <= cutoffTime
            ) {
              firstState.start = startTime;
            } else {
              result.history.push({
                id: NO_DATA_STATE_ID,
                label: "No Data",
                start: startTime,
                end: firstState.start,
                minDuration: 0,
              });
            }
          }
        }

        // If we're missing data at the end of the intended period, fill it in
        const lastState = result.history[0];
        if (lastState.end < endTime) {
          if (edgesOnly) {
            let ctime = new Date().valueOf();
            lastState.end = Math.min(endTime, ctime);
          } else {
            // just as at start, maintain last state if already in no data
            // state, or if not in no data state and haven't reached cutoff time
            // for transition to no data state; however, also take into account
            // update interval as data could just be not available yet rather
            // than missing
            if (
              lastState.id === NO_DATA_STATE_ID ||
              endTime - lastState.end <= cutoffTime
            ) {
              lastState.end = endTime;
            } else {
              console.log(
                "need to add missing at end",
                key,
                lastState.end,
                endTime - lastState.end,
                cutoffTime,
                result.pending
              );
              result.history.unshift({
                id: NO_DATA_STATE_ID,
                label: "No Data",
                start: lastState.end,
                end: endTime,
                minDuration: 0,
              });
            }
          }
        }
      }
    }

    postMessage({ dataset, result, stateCounts, key });
  });

  function computeAverageInterval(timeSeries) {
    // average the delta between sample times over the last 100 recent samples
    const averageInterval =
      timeSeries.length > 0
        ? timeSeries.slice(-100).reduce(
            (result, datum, index, source) => {
              if (index === source.length - 1) {
                return result.total / result.count;
              }
              return {
                total: result.total + (source[index + 1].x - datum.x),
                count: result.count + 1,
              };
            },
            { total: 0, count: 0 }
          )
        : 0;
    return averageInterval;
  }

  // Full timeseries data
  function convertTimeSeriesAndStateMapToHistory(
    timeSeries,
    states,
    eventInterval,
    cutoffTime
  ) {
    // console.log({ eventInterval, cutoffTime });

    const collapsedData = timeSeries.reduce(
      processStateTransitions(states, eventInterval, cutoffTime),
      {
        history: [],
        potential: [],
      }
    );

    return collapsedData;
  }

  function processStateTransitions(states, eventInterval, cutoffTime) {
    return (currentStatus, data, index, array) => {
      const updatedStatus = handleMissingDataCheck(
        currentStatus,
        data,
        cutoffTime
      );

      const hasHistory = updatedStatus.history.length > 0;
      const currentState = hasHistory ? updatedStatus.history[0] : null;

      // Find states for which the current data qualifies
      let newStates = getStatesFromValue(data, states);
      if (
        newStates.some((state) => hasHistory && currentState.id === state.id)
      ) {
        newStates = newStates.filter((state) => currentState.id === state.id);
      }

      // Look at ongoing potential states to transition to, ensure they are in
      // the states possible with this data, then extend the end time if found
      const continuedPotentials = updatedStatus.potential
        .filter((state) =>
          newStates.some((newState) => state.id === newState.id)
        )
        .map((state) => ({
          id: state.id,
          label: state.label,
          start: state.start,
          end: data.x,
          minDuration: state.minDuration,
        }));

      // See if any meet their threshholds; Math is end - start + one interval so
      // if the interval is 1s, 00:01 - 00:02 = 2 seconds to cover 00:00.5 -
      // 00:02.5; So 01:04 - 01:08 meets the threshhold for 5 seconds
      const completedPotential = continuedPotentials
        .concat(newStates)
        .filter((state) => !hasHistory || currentState.id !== state.id)
        .find(
          (state) =>
            !hasHistory ||
            state.end - state.start + eventInterval >= state.minDuration * 1000
        );

      // Process state based on coninuing and completed states
      if (!completedPotential) {
        // No new states were officially entered

        // Build the list of new possible states starting now; Go through the
        // current possible states, remove any possible continuing ones; Then
        // remove it if it matches the current state (end of History)
        const newPotentials = newStates
          .filter((state) =>
            continuedPotentials.every((potState) => state.id !== potState.id)
          )
          .filter((state) => !hasHistory || state.id !== currentState.id);

        if (hasHistory && continuedPotentials.length === 0) {
          // Since there aren't any states continuing from the last period, we
          // can't leave the current state so update its end time
          updatedStatus.history = adjustEndTimeOfCurrentState(
            updatedStatus.history,
            data.x
          );
        }

        //Set the final list to the one just generated
        updatedStatus.potential = continuedPotentials.concat(newPotentials);
      } else {
        // We are transitioning to a new state
        // - Update start time of new state and end time of prev state
        // - Push the state to the history list
        // - Clear any potential state transitions

        // adjust start time of completed potential and end time of prev state
        if (currentState) {
          // transition time interpolated between two states
          const transitionTime =
            (currentState.end + completedPotential.start) / 2;
          completedPotential.start = transitionTime;
          currentState.end = transitionTime;
        }
        updatedStatus.history = [completedPotential].concat(
          updatedStatus.history
        );
        updatedStatus.potential = [];
      }

      // Final check if we're at the end
      if (index === array.length - 1) {
        // Move end point of last known state to now
        updatedStatus.history = adjustEndTimeOfCurrentState(
          updatedStatus.history,
          data.x
        );
        updatedStatus.potential = [];
      }

      return updatedStatus;
    };
  }

  function getStatesFromValue(data, states) {
    const possibleStates = states
      .filter(
        (state) => state.lowerBound <= data.y && state.upperBound >= data.y
      )
      .map((state) => ({
        id: state.id,
        label: state.label,
        start: data.x,
        end: data.x,
        minDuration: state.minDuration,
      }));

    return possibleStates.length > 0
      ? possibleStates
      : [
          {
            id: UNCATEGORIZED_STATE_ID,
            label: "UNCATEGORIZED",
            start: data.x,
            end: data.x,
            minDuration: 0,
          },
        ];
  }

  // Take a list, modify the end field of the last item to be the new value and
  // return a new list
  function adjustEndTimeOfCurrentState(history, newEndTime) {
    if (!history || history.length === 0) {
      return history;
    }

    return [
      {
        id: history[0].id,
        label: history[0].label,
        start: history[0].start,
        end: newEndTime,
        minDuration: history[0].minDuration,
      },
    ].concat(history.slice(1));
  }

  function handleMissingDataCheck(currentStatus, data, cutoffTime) {
    // Return if at the beginning OR if this is a consecutive result
    const hasHistory = currentStatus.history.length > 0;
    const currentState = hasHistory ? currentStatus.history[0] : null;

    const hasPotentials = currentStatus.potential.length > 0;

    const lastSeenDataTime = hasPotentials
      ? currentStatus.potential[0].end // If there are potential states, they will be the most recent times
      : hasHistory
      ? currentState.end // If there aren't potential states, the history will be the most recent
      : null; // If we're at the first, this won't matter

    // determine if there is a gap long enough to indicate missing data
    if (!lastSeenDataTime || data.x - lastSeenDataTime < cutoffTime) {
      return currentStatus;
    }

    // From here we know we have a gap in time
    // We ignore whatever possible states there were and make the last known
    // state this missing data
    // If the previous known state is also No Data, we simply extend it

    // Create new object to return
    const newStatus = {};

    // Add a new No Data state to the history, ending the previous state and
    // sarting the new state calculation fresh
    if (hasHistory && currentState.id === NO_DATA_STATE_ID) {
      // The previous state was No Data, so just continue it
      // This custs off the last element of the current history array, then adds
      // back the modified previous end
      newStatus.history = adjustEndTimeOfCurrentState(
        currentStatus.history,
        data.x
      );
    } else {
      // The previous state was not No Data or didn't exist, so add a new one

      // Move the end of the last state to be the start of this gap
      newStatus.history = adjustEndTimeOfCurrentState(
        currentStatus.history,
        lastSeenDataTime
      );

      // Add the new No Data segment starting one interval after the last point
      // and ending before the current one
      newStatus.history = [
        {
          id: NO_DATA_STATE_ID,
          label: "No Data",
          start: lastSeenDataTime,
          end: data.x,
          minDuration: 0,
        },
      ].concat(newStatus.history);
    }
    newStatus.potential = [];
    return newStatus;
  }

  // Just transitions - so every entry is a new time
  function convertEdgesOnlyTimeSeriesToHistory(
    timeSeries,
    stateLabels,
    collapseByLabel = true
  ) {
    let labelToIdMap = {};
    if (collapseByLabel) {
      for (let i = 0; i < stateLabels.length; i++) {
        if (!(stateLabels[i] in labelToIdMap)) {
          labelToIdMap[stateLabels[i]] = i + 1;
        }
      }
    }
    const collapsedData = timeSeries.reduce(
      (result, data) => {
        const newState = {
          //id: data.y === 0 ? 1 : 2,
          //label: data.y === 0 ? stateLabels[0] : stateLabels[1], // ? "Off" : "On"
          //id: data.y + 1,
          id: collapseByLabel ? labelToIdMap[stateLabels[data.y]] : data.y + 1,
          label: stateLabels[data.y],
          start: data.x,
          end: data.x,
          minDuration: null,
        };

        if (result.history.length === 0) {
          result.history = [newState];
        } else {
          const previousState = {
            id: result.history[0].id,
            label: result.history[0].label,
            start: result.history[0].start,
            end: data.x,
            minDuration: null,
          };

          if (newState.label === previousState.label) {
            // should this be possible?
            result.history = [previousState].concat(result.history.slice(1));
          } else {
            result.history = [newState, previousState].concat(
              result.history.slice(1)
            );
          }
        }
        return result;
      },
      {
        history: [],
        potential: [],
      }
    );

    return collapsedData;
  }
};

export default stateTableProcessingWorker;
