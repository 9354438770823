// TODO: remove this direct dependency on the auth service. This service should
// only be required by the store.
import auth from "./Auth";
import _partition from "lodash/partition";
import _sample from "lodash/sample";

const ISX_GENERIC_EVENTS_TAG = "isxreserved|events";
const LOG_C2D_MESSAGE_IN_EVENTS_TAG = true;

// const ISX_CLOUD_URL =
//       "https://ekmrqucmc5.execute-api.us-east-1.amazonaws.com/prod/isxReg2?op=";
// const query = (op, body) => {
//   return fetch(ISX_CLOUD_URL + op, {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify(body)
//   }).then(response => {
//     return response.json();
//   });
// };

const _createOrgIdsParams = (orgids) =>
  [...orgids].map((oid) => `orgids=${oid}`).join("&");

const QueryService = {
  // login: (username, password) =>
  //   query("login", {
  //     email: username,
  //     password: password
  //   }),

  getDashboard: async (_, __, guuid) => {
    const response = await auth.fetch(`/api/dashboards/${guuid}`);
    const dashboard = response.json();
    return dashboard;
  },
  createDashboard: async (item) => {
    const response = await auth.fetch(`/api/dashboards`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const dashboard = await response.json();
    return dashboard;
  },
  updateDashboard: async (_, __, guuid, item) => {
    const response = await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const dashboard = await response.json();
    return dashboard;
  },
  removeDashboard: async (guuid) => {
    await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "DELETE",
    });
  },
  unlockDashboard: async (guuid, force = false) => {
    const request = {
      method: "PUT",
    };
    if (force) {
      request.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      request.body = JSON.stringify({ force: true });
    }
    await auth.fetch(`/api/dashboards/${guuid}/write-access`, request);
  },
  lockDashboard: async (guuid, force = false) => {
    const request = {
      method: "DELETE",
    };
    if (force) {
      request.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      request.body = JSON.stringify({ force: true });
    }
    await auth.fetch(`/api/dashboards/${guuid}/write-access`, request);
  },
  shareDashboard: async (guuid, orgs) => {
    const request = {
      method: "PATCH",
    };
    request.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    request.body = JSON.stringify({ orgs });
    const response = await auth.fetch(
      `/api/dashboards/${guuid}/share`,
      request
    );
    if (response.status === 204) {
      return {
        status: 0,
      };
    } else {
      return {
        status: -1,
        error: response.body,
      };
    }
  },
  getAllItems: async () => {
    const response = await auth.fetch(`/api/items`);
    const items = await response.json();
    return items;
  },
  getItem: async (_, __, guuid /*, attrs */) => {
    const response = await auth.fetch(`/api/items/${guuid}`);
    const item = await response.json();
    return item;
  },
  getItem2: async (guuid, fields = null) => {
    let response;
    if (fields) {
      const fieldsQueryParams = fields.map((field) => `fields=${field}`);
      response = await auth.fetch(
        `/api/items/${guuid}?${fieldsQueryParams.join("&")}`
      );
    } else {
      response = await auth.fetch(`/api/items/${guuid}`);
    }
    const item = await response.json();
    return item;
  },
  getItems: async (_, __, guuids /*, attrs */) => {
    const queryParams = guuids.map((guuid) => `ids=${guuid}`).join("&");
    const response = await auth.fetch(`/api/items?${queryParams}`);
    const items = await response.json();
    return items;
  },
  rangequery: async (_, __, guuid, attrs, start, end, orgids = null) => {
    if (typeof attrs === "string") {
      attrs = [attrs];
    }
    const attrQueryParams = attrs
      .map((attr) => `attrs[${guuid}]=${attr}`)
      .join("&");
    let request = `/api/tags?start=${start}&end=${end}&items=${guuid}&${attrQueryParams}`;
    if (orgids) {
      request += `&${_createOrgIdsParams(orgids)}`;
    }
    const response = await auth.fetch(request);
    const tags = await response.json();

    // Convert to old range query structure
    const tag = tags[0];
    const [, itemUUID] = tag.guuid.match(/(.*?)_(.*)/);
    let items = {
      guuid: itemUUID,
      ds: {},
      start,
      end,
    };
    tags.forEach((tag) => {
      const [, , tagType] = tag.guuid.match(/(.*?)_(.*)/);
      items["ds"][tagType] = tag.data;
    });
    items.is_finalized = tags.every((tag) => tag.is_finalized);

    return { items, status: 0 };
  },
  rangequeryLatestOverPeriod: async (guuid, attrs, period, orgids = null) => {
    if (typeof attrs === "string") {
      attrs = [attrs];
    }
    const attrQueryParams = attrs
      .map((attr) => `attrs[${guuid}]=${attr}`)
      .join("&");
    let request = `/api/tags?latest_over_period=${period}&items=${guuid}&${attrQueryParams}`;
    if (orgids) {
      request += `&${_createOrgIdsParams(orgids)}`;
    }
    const response = await auth.fetch(request);
    const tags = await response.json();
    // Convert to old range query structure
    const tag = tags[0];
    const [, itemUUID] = tag.guuid.match(/(.*?)_(.*)/);
    // const end = moment.utc();
    // const start = moment.utc(end).subtract(period, "milliseconds");
    let items = {
      guuid: itemUUID,
      ds: {},
      // start: start.valueOf(),
      // end: end.valueOf(),
    };
    tags.forEach((tag) => {
      const [, , tagType] = tag.guuid.match(/(.*?)_(.*)/);
      items["ds"][tagType] = tag.data;
    });
    items.is_finalized = tags.every((tag) => tag.is_finalized);

    return { items, status: 0 };
  },
  rangequeries: async (tags, start, end, orgids = null) => {
    const tagQueryParams = tags
      .map((tag) => `ids=${tag.guuid}_${tag.attribute}`)
      .join("&");
    let request = `/api/tags?start=${start}&end=${end}&${tagQueryParams}&ignore_missing=1&ignore_unauthorized=1`;
    if (orgids) {
      request += `&${_createOrgIdsParams(orgids)}`;
    }
    const response = await auth.fetch(request);
    const results = await response.json();

    // Convert to old range query structure
    return results.map((result) => {
      const [, guuid, attr] = result.guuid.match(/(.*?)_(.*)/);
      return {
        guuid,
        attr,
        data: result.data,
        is_finalized: result.is_finalized,
      };
    });
  },
  rangequeriesLatestOverPeriod: async (tags, period, orgids = null) => {
    const tagQueryParams = tags
      .map((tag) => `ids=${tag.guuid}_${tag.attribute}`)
      .join("&");
    let request = `/api/tags?latest_over_period=${period}&${tagQueryParams}&ignore_missing=1&ignore_unauthorized=1`;
    if (orgids) {
      request += `&${_createOrgIdsParams(orgids)}`;
    }
    const response = await auth.fetch(request);
    const results = await response.json();

    // Convert to old range query structure
    return results.map((result) => {
      const [, guuid, attr] = result.guuid.match(/(.*?)_(.*)/);
      return {
        guuid,
        attr,
        data: result.data,
        is_finalized: result.is_finalized,
      };
    });
  },
  notesQuery: async (item, tag, count, cursor, start, end) => {
    const tag_id = `${item}_${tag}`;
    const qparams = {};
    qparams.include_provenance = 1;
    if (count != null) {
      qparams.count = count;
    }
    if (cursor) {
      qparams.cursor = cursor;
    }
    if (start != null) {
      qparams.start = start;
    }
    if (end != null) {
      qparams.end = end;
    }
    const queryParams = Object.entries(qparams)
      .map(([k, v]) => `${k}=${v}`)
      .join("&");
    let url = `/api/tags/${tag_id}`;
    if (queryParams) {
      url += `?${queryParams}`;
    }
    const response = await auth.fetch(url);
    return await response.json();
  },
  alertsQuery: async (items, start, end) => {
    if (typeof items === "string") {
      items = [items];
    }
    // pipe ("|") character bad for AWS, must url encode
    const idQueryParams = encodeURI(
      items.map((guuid) => `ids=${guuid}_isxreserved|alerts`).join("&")
    );
    const response = await auth.fetch(
      `/api/tags?start=${start}&end=${end}&${idQueryParams}&ignore_missing=1`
    );
    const tags = await response.json();
    return tags;
  },
  eventsQuery: async (items, start, end) => {
    if (typeof items === "string") {
      items = [items];
    }
    // pipe ("|") character bad for AWS, must url encode
    const idQueryParams = encodeURI(
      items.map((guuid) => `ids=${guuid}_isxreserved|events`).join("&")
    );
    const response = await auth.fetch(
      `/api/tags?start=${start}&end=${end}&${idQueryParams}&ignore_missing=1`
    );
    const tags = await response.json();
    return tags;
  },
  themesQuery: async (themeids) => {
    if (typeof themeids === "string") {
      themeids = [themeids];
    }
    const themeidQueryParams = themeids
      .map((themeid) => `themeids=${themeid}`)
      .join("&");

    const response = await fetch(`/api/themes?${themeidQueryParams}`);
    const themes = await response.json();
    return themes;
  },
  inviteUsers: async (_, __, orgid, invites) => {
    try {
      const response = await auth.fetch(`/api/orgs/${orgid}/invites`, {
        method: "POST",
        body: JSON.stringify({ invited: invites }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 204) {
        return {
          status: 0,
          info: "All invites sent",
        };
      } else if (response.status === 503) {
        return {
          status: 0,
          info: "Partial invites sent",
        };
      } else {
        return {
          status: -1,
        };
      }
    } catch (err) {
      return {
        status: -1,
        error: err.message,
      };
    }
  },
  createOrUpdateUsers: async (_, __, orgid, users) => {
    try {
      const response = await auth.fetch(`api/orgs/${orgid}/users/additions`, {
        method: "POST",
        body: JSON.stringify({ users: users }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      //console.log("response is", result);
      if (response.status === 200) {
        return {
          status: result.status,
          results: result.results,
        };
      } else {
        return {
          status: -1,
        };
      }
    } catch (err) {
      return {
        status: -1,
        error: err.message,
      };
    }
  },
  getInvites: async (_, __, orgid, filterstatus) => {
    try {
      let url = `/api/orgs/${orgid}/invites`;
      if (filterstatus) {
        url += `?filter_status=${filterstatus}`;
      }
      const response = await auth.fetch(url);
      const items = await response.json();
      return { items, status: 0 };
    } catch (err) {
      return {
        status: -1,
        error: err,
      };
    }
  },
  // getOrgs: async (_, __, guuids) => {
  //   const batches = _chunk(guuids, ORGS_BATCH_SIZE);
  //   try {
  //     const promises = batches.map((batch) => {
  //       const queryParams = batch.map((guuid) => `ids=${guuid}`).join("&");
  //       return auth.fetch(`/api/orgs?${queryParams}`);
  //     });
  //     const results = await Promise.all(promises);
  //     const itemBatches = await Promise.all(
  //       results.map((response) => response.json())
  //     );
  //     return { status: 0, items: itemBatches.flat() };
  //   } catch (err) {
  //     return {
  //       status: -1,
  //       error: err.message,
  //     };
  //   }
  // },
  removeOrgUsers: async (_, __, orgid, users) => {
    try {
      const queryParams = users.map((guuid) => `ids=${guuid}`).join("&");
      const response = await auth.fetch(
        `/api/orgs/${orgid}/users/removals?${queryParams}`,
        {
          method: "PATCH",
        }
      );
      const result = await response.json();
      return result && result.status !== "completed"
        ? {
            status: -2,
            info: "Not all users removed, please retry",
          }
        : {
            status: 0,
            info: "users successfully removed from org",
          };
    } catch (err) {
      return {
        status: -1,
        error: err.message,
      };
    }
  },
  createOrg: async (_, __, orgitem) => {
    const response = await auth.fetch("/api/orgs", {
      method: "POST",
      body: JSON.stringify(orgitem),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const neworg = await response.json();
    return {
      status: 0,
      info: "org created",
      orgid: neworg.guuid,
    };
  },
  sendStackMessage: async (username, __, guuid, props, stkey) => {
    await auth.fetch(`/api/items/${guuid}/c2d`, {
      method: "PATCH",
      body: JSON.stringify({
        message: props,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (LOG_C2D_MESSAGE_IN_EVENTS_TAG && stkey) {
      const msgtosend = {
        guuid: guuid,
        access_key: stkey,
        op: "update",
        st: {
          [ISX_GENERIC_EVENTS_TAG]: [
            [
              Date.now(),
              {
                evcat: "user",
                user: username,
                data: props,
                evtype: "c2d",
              },
            ],
          ],
        },
      };
      await QueryService.sendProcessMessage(msgtosend);
    }
    return { status: 0, info: "c2d msg added" };
  },

  sendProcessMessage: async (props) => {
    let isxURL =
      "https://p4d812n74f.execute-api.us-east-1.amazonaws.com/dev/isxItem";
    props.dnss = true; //set flag to prevent requesting dev session in response
    try {
      let response = await auth.fetch(isxURL, {
        method: "PUT",
        //mode: "cors",
        //body: JSON.stringify(props),
        body: JSON.stringify(props),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Origin: "localhost:3000",
        },
      });
      if (response.status === 200) {
        const results = await response.json();
        return {
          status: 0,
          message: results,
        };
      } else {
        return {
          status: -1,
        };
      }
    } catch (err) {
      return {
        status: -1,
        error: err.message,
      };
    }
    /*.then((response) => response.json())
      .then((jsondata) => {
        console.log("jsondata", jsondata);
        return jsondata;
      });*/

    /*console.log("response for process request", response);
    // If we received a forbidden error, attempt to refresh and then retry.
    if (response.status === 403) {
      console.log("error");
    }

    if (!response.ok) {
      let responseBody;
      try {
        responseBody = await response.json();
      } catch (error) {
        throw new Error(response.statusText || response.status);
      }

      throw new Error(
        responseBody.Message ||
          responseBody.Code ||
          response.statusText ||
          response.status
      );
    }
    return response;*/
  },
  updateAlarm: async (_, __, guuid, params) => {
    await auth.fetch(`/api/items/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        data_config: params,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { status: 0, info: "updated" };
  },
  updateDataConfig: async (_, __, guuid, params) => {
    await auth.fetch(`/api/items/${guuid}`, {
      method: "PATCH",
      /*body: JSON.stringify({
        data_config: params,
      }),*/
      body: JSON.stringify(params),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return { status: 0, info: "updated" };
  },
  sendMessages: async (messageType, messagePayload, recipients) => {
    try {
      const response = await auth.fetch(`/api/messages`, {
        method: "POST",
        body: JSON.stringify({
          message_type: messageType,
          message_payload: messagePayload,
          recipients,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 207) {
        const results = await response.json();
        const [succeeded, failed] = _partition(
          results,
          (entry) => entry.status === 200
        ).map((part) => part.map((entry) => entry.recipient));
        return {
          status: 0,
          succeeded,
          failed,
        };
      } else {
        return {
          status: -1,
        };
      }
    } catch (err) {
      return {
        status: -1,
        error: err.message,
      };
    }
  },
  // TODO: implement these functions in new API
  // updateCalibration: (username, password, guuid, params) =>
  //   query("updateitem", {
  //     email: username,
  //     password: password,
  //     guuid: guuid,
  //     item: params
  //   }),
  // getReport: (username, password, guuid, attrs, start, end) =>
  //   query("getreport", {
  //     email: username,
  //     password,
  //     guuid: guuid,
  //     attrs: attrs,
  //     start: start,
  //     end: end
  //   })
  createNote: async (item_id, tag, note, orgids = null) => {
    let request = `/api/tags`;
    if (orgids) {
      request += `&${_createOrgIdsParams(orgids)}`;
    }
    const payload = {
      dsid: `${item_id}_${tag}`,
      ds: [
        [
          null,
          {
            value: note,
          },
        ],
      ],
    };

    const response = await auth.fetch(request, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 204) {
      return {
        status: 0,
      };
    } else {
      return {
        status: -1,
        error: response.body,
      };
    }
  },
  createTopicTag: async (item_id, name = null, forTag = null) => {
    // handle exceptions in caller?
    let request = `/api/items/${item_id}/tag?prefix=topic&type=topic`;
    if (name) {
      request += `&name=${name}`;
    }
    if (forTag) {
      request += `&for-tag=${forTag}`;
    }
    const response = await auth.fetch(request, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const payload = await response.json();
    return payload.tag_id;
  },
};

window._qs = QueryService;
export default QueryService;
