import React, { useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TimePicker } from "@material-ui/pickers";

import { default as _last } from "lodash/last";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import moment from "moment-timezone";
import { useEffect } from "react";
const uuidv4 = require("uuid/v4");

const DAYS_OF_WEEK = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

const isPositiveInteger = (val) => parseInt(val) === Number(val) && val > 0;

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "column",
    width: "calc(100% - 48px)",
  },
  timezoneContainer: {
    marginTop: theme.spacing(1),
  },
  innerContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const Period = (props) => {
  const { period, timezone, update, remove } = props;
  const classes = useStyles();

  const updateDays = (event) => {
    const { value: options } = event.target;
    const value = DAYS_OF_WEEK.filter((label) => options.indexOf(label) !== -1);
    update({ byweekday: value });
  };

  return (
    <Grid container className={classes.innerContainer}>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        wrap="wrap"
        className={classes.detailsContainer}
      >
        <Grid item xs={12} md={4}>
          <FormControl margin="dense" fullWidth={true}>
            <TimePicker
              key={timezone || "local"}
              id={`starttime-${period.guuid}`}
              label="Start Time"
              value={moment.utc(period.start ?? 0).set("second", 0)}
              onChange={(dt) => {
                update({
                  start: dt.valueOf(),
                });
              }}
              error={period.start == null}
            />
            {period.start == null && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Please enter a start time for period/shift
              </p>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl margin="dense" fullWidth={true}>
            <TextField
              id={`period-length-value-${period.guuid}`}
              label="Duration (in hours)"
              value={period["duration.value"] ?? ""}
              onChange={(event) => {
                update({
                  "duration.value": event.target.value,
                });
              }}
              type="number"
              error={period["duration.value"] <= 0}
            />
            {period["duration.value"] <= 0 && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Please enter a duration for period/shift in hours, must be
                greater than 0 and less than or equal to 24
              </p>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl
            margin="dense"
            fullWidth={true}
            style={{ marginTop: 11 }}
          >
            <InputLabel error={period.byweekday.length === 0}>
              Days of Week
            </InputLabel>
            <Select
              labelId={`period-days-label-${period.guuid}`}
              id={`period-days-${period.guuid}`}
              multiple
              value={period.byweekday ?? []}
              onChange={updateDays}
              input={<Input />}
              renderValue={(selected) => (
                <div style={{ fontSize: ".75rem" }}>{selected.join(",")}</div>
              )}
              error={period.byweekday.length === 0}
            >
              {DAYS_OF_WEEK.map((label) => (
                <MenuItem dense key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {period.byweekday.length === 0 && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Please select the days of the week period/shift is active
              </p>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl margin="dense" fullWidth={true}>
            <TextField
              id={`period-label-${period.guuid}`}
              label="Label"
              value={period.label}
              onChange={(event) => {
                update({
                  label: event.target.value,
                });
              }}
              margin="dense"
              error={!period.label}
            />
            {!period.label && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Please provide a label for period/shift
              </p>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl margin="dense" fullWidth={true}>
            <TextField
              id={`period-description-${period.guuid}`}
              label="Description (optional)"
              value={period.description}
              onChange={(event) => {
                update({
                  description: event.target.value,
                });
              }}
              margin="dense"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={remove}
              size="small"
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              Remove Period/Shift
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PeriodsOption = (props) => {
  const scrollRef = useRef();
  const addingPeriod = useRef(false);

  const { options, setOptions, errors, errorDetected, noErrorDetected } = props;

  const timezone = options.timezone;
  const { periods = [], periods_to_show: periodsToShow = 0 } = options;

  const classes = useStyles();

  const updatePeriod = (guuid) => (update) => {
    const idx = periods.findIndex((p) => p.guuid === guuid);
    if (idx !== -1) {
      const period = periods[idx];
      const newPeriods = [...periods];
      newPeriods[idx] = { ...period, ...update };
      setOptions({
        periods: newPeriods,
      });
    }
  };

  // check all periods for validity, and signal error if at least one incorrectly configured
  useEffect(() => {
    let haveErrors = false;
    periods.forEach((period) => {
      // each period should have valid start, byweekday and label
      const { start, "duration.value": duration, byweekday, label } = period;
      if (start == null || !duration || byweekday.length === 0 || !label) {
        haveErrors = true;
      }
    });
    if (haveErrors && !errors.periods) {
      errorDetected("periods");
    } else if (!haveErrors && errors.periods) {
      noErrorDetected("periods");
    }
  }, [errorDetected, noErrorDetected, periods, errors.periods]);

  useEffect(() => {
    if (addingPeriod.current) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behaviour: "smooth" });
      }
      addingPeriod.current = false;
    }
  }, [periods]);

  const createPeriod = () => {
    const newPeriod = {
      guuid: uuidv4(),
      "duration.units": "hours",
      "duration.value": 0,
      byweekday: [],
      label: "",
      description: "",
    };
    setOptions({
      periods: [...periods, newPeriod],
    });
    addingPeriod.current = true;
    // scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const removePeriod = (guuid) => () => {
    setOptions({
      periods: periods.filter((p) => p.guuid !== guuid),
    });
  };

  return (
    <FormControl
      margin="dense"
      style={{ width: "calc(100% - 48px)", margin: 24 }}
    >
      <FormLabel component="legend">Multi-tag Report</FormLabel>
      <div>
        <Typography
          variant="overline"
          style={{ marginRight: 6 }}
        >{`${periods.length} configured`}</Typography>
        <Button
          className={classes.alertsAction}
          color="primary"
          onClick={createPeriod}
          startIcon={<AddCircleIcon />}
        >
          Create New
        </Button>
      </div>
      <Grid container>
        {periods.length > 0 ? (
          <>
            {periods.map((period) => (
              <div
                key={period.guuid}
                ref={period === _last(periods) ? scrollRef : null}
              >
                <Period
                  period={period}
                  timezone={timezone}
                  update={updatePeriod(period.guuid)}
                  remove={removePeriod(period.guuid)}
                />
              </div>
            ))}
          </>
        ) : (
          <div></div>
        )}
        <Grid item xs={12} md={6}>
          <FormControl margin="dense" fullWidth={true}>
            <TextField
              id="periods-count"
              label="# periods to show"
              value={periodsToShow ?? ""}
              onChange={(event) => {
                setOptions({
                  periods_to_show: event.target.value,
                });
                if (!isPositiveInteger(event.target.value)) {
                  errorDetected("periodsToShow");
                } else {
                  noErrorDetected("periodsToShow");
                }
              }}
              type="number"
              error={!isPositiveInteger(periodsToShow)}
            />
            {!isPositiveInteger(periodsToShow) && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Please enter a positive value for # periods to show
              </p>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default PeriodsOption;
