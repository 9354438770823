import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import TagRolePropertiesDialog from "components/TagRolePropertiesDialog";
import { STATES_ROLE } from "services/RolesService";

const useStyles = makeStyles({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
});

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const StateReportingOption = (props) => {
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);

  const { options = {}, setOptions, tagsSelected } = props;
  const { statereporting: stateReportingOptions = {} } = options;
  const { outputUnit: summaryUnit = "percent" } = stateReportingOptions;

  const classes = useStyles();

  const setSummaryUnit = (event) =>
    setOptions({ statereporting: { outputUnit: event.target.value } });

  const tag = Object.values(tagsSelected ?? {})[0];

  return (
    <FormControl
      margin="dense"
      style={{ width: "calc(100% - 48px)", margin: 24 }}
    >
      <FormLabel>Display values as:</FormLabel>
      <RadioGroup
        aria-label="summaryUnit"
        name="summaryUnit"
        value={summaryUnit}
        onChange={(event) => setSummaryUnit(event)}
        row
      >
        <FormControlLabel
          value="percent"
          control={<Radio />}
          label="Percentage of time"
        />
        <FormControlLabel value="time" control={<Radio />} label="Total time" />
        <FormControlLabel value="count" control={<Radio />} label="Counts" />
      </RadioGroup>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="caption"
          style={{ fontSize: "0.66rem", fontStyle: "italic" }}
        >
          The state levels are configured in the Device Tags properties.
        </Typography>
        <InfoTooltip
          title="Click to view/modify states properties"
          onClick={(e) => {
            setRoleDialogOpen(true);
          }}
        >
          <OpenInNewIcon fontSize="small" className={classes.openIcon} />
        </InfoTooltip>
      </div>
      <TagRolePropertiesDialog
        open={roleDialogOpen}
        setOpen={setRoleDialogOpen}
        tag={tag}
        role={STATES_ROLE}
      />
    </FormControl>
  );
};

export default StateReportingOption;
